import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import {getUrlFromImage} from "../components/Gallery";

// eslint-disable-next-line
export const TuinaanlegPageTemplate = class extends React.Component {
  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#fff');
  }
  render() {
    return (
      <div>
        <section className="header compact">
        <div className="header-image" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${getUrlFromImage(this.props.titleImage)})`}}></div>
          <div className="container">
            <h1 className="title">TUINAANLEG</h1>
          </div>
        </section>
        <section className="content container mt-6 mb-6">
          <p>Naast het uittekenen van uw tuin kunnen wij deze van a tot z aanleggen. <br/>Uw plan wordt door ons voor 100% vertaald naar realiteit.</p>
        </section>
        <section className="content container mt-6 mb-6"> 
          
          <div className="row mt-5">
            {
              this.props.fotos.map(foto => {
                return (
                  <div className="col-md-4">
                    <div className="foto-project" style={{background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)),url(${getUrlFromImage(foto.image)}) top center / cover no-repeat`}}>
                      <span className="foto-text">{foto.text}</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </section>
      </div>
    );
  }
}

TuinaanlegPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const TuinaanlegPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TuinaanlegPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        fotos={post.frontmatter.fotosTuinaanleg}
        titleImage={post.frontmatter.titleImage}
      />
    </Layout>
  );
};

TuinaanlegPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TuinaanlegPage;

export const tuinaanlegPageQuery = graphql`
  query TuinaanlegPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        titleImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 60) {
              ...GatsbyImageSharpFluid
            }
            original {
              width
              height
            }
          }
        }
        fotosTuinaanleg {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
      }
    }
  }
`;
